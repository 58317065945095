import CURRENT_STORE_VERSION from "./version";

export const migration = {
  [CURRENT_STORE_VERSION]: (state) => {
    return {
      ...state,
      appIdsSlice: {
        familyMembersIds: state.appIdsSlice.familyMembersIds,
        familyMembers: state.appIdsSlice.familyMembers,
      },
    };
  },
};
