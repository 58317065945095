import { ILoginInformation } from "../../app/interfaces/ILoginInformation.interface";
import { ITokenResponse } from "../../app/interfaces/ITokenResponse.interface";

export const getFirstAndLastName = (data: ILoginInformation): string => {
  if (!data) return;
  const {
    patientResponse: { firstName, lastName },
  } = data;

  return `${firstName} ${lastName.charAt(0)}.`;
};

export const setCredentialsToSessionStorage = (data: ITokenResponse) => {
  Object.entries(data).forEach(([key, value]) => {
    sessionStorage.setItem(key, value);
  });
};
