import { CardMedia, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import loadingImg from "../../../assets/loading.gif";
interface ImageContainerPropTypes {
  img?: string;
}
const useStyles = makeStyles({
  root: {
    maxWidth: "350px",
  },
  loading: {
    marginTop: "80%",
    maxWidth: 275,
    minWidth: 250,
  },
});
const ImageContainer: React.FC<ImageContainerPropTypes> = ({
  img = loadingImg,
}: ImageContainerPropTypes) => {
  const classes = useStyles();
  return (
    <Grid container justifyContent="center">
      <Grid item>
        <CardMedia
          className={img === loadingImg ? classes.loading : classes.root}
          component="img"
          alt="Image container"
          image={img}
          title="Image container"
        />
      </Grid>
    </Grid>
  );
};

export default ImageContainer;
