import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  TOKEN_TYPE,
  EXPIRES_IN,
} from "../../constants/api.constant";

export const removeTokenData = (): void => {
  sessionStorage.removeItem(ACCESS_TOKEN);
  sessionStorage.removeItem(REFRESH_TOKEN);
  sessionStorage.removeItem(TOKEN_TYPE);
  sessionStorage.removeItem(EXPIRES_IN);
};
