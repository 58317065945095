import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { Mutex } from "async-mutex";
import { RootStateOrAny } from "react-redux";

import {
  resetFamilyMembers,
  resetIdsExpectPatientId,
} from "../features/app/appIdsSlice";
import { setCredentialsToSessionStorage } from "../features/Login/loginHelpers";
import { setIsSessionExpired } from "../features/Logout/logoutSlice";
import { ACCESS_TOKEN } from "./constants/api.constant";
import { OAuthGrantType } from "./enums/EOAuthGrantType.enum";
import { ITokenResponse } from "./interfaces/ITokenResponse.interface";
import { paths } from "./routes/paths";
import {
  apiHeaders,
  getRefreshToken,
  getToken,
  removeTokenData,
} from "./utils/api";

const mutex = new Mutex();
const getBaseQuery = (baseUrl: string) =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const memberId = (getState() as RootStateOrAny)?.appIdsSlice
        .familyMembersIds?.[0];
      return apiHeaders(memberId?.practiceId, headers);
    },
  });

export const baseQueryWithReauth =
  (
    baseURL: string
  ): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =>
  async (args, api, extraOptions) => {
    await mutex.waitForUnlock();
    const baseQuery = getBaseQuery(baseURL);
    const accessToken = getToken();
    let result = await baseQuery(args, api, extraOptions);

    const isError =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      result?.error?.originalStatus === 401 ||
      result?.error?.status === 401 ||
      result?.error?.status === "FETCH_ERROR";

    if (result.error && isError && accessToken) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        const refreshTokenParams = getRefreshToken();

        try {
          const refreshResult = await baseQuery(
            {
              method: "POST",
              url: `${process.env.REACT_APP_API_HOST}api/v1/oauth/token`,
              body: {
                grant_type: OAuthGrantType.RefreshToken,
                refresh_token: refreshTokenParams,
              },
            },
            api,
            extraOptions
          );

          if (refreshResult.data) {
            sessionStorage.removeItem(ACCESS_TOKEN);
            api.dispatch(setIsSessionExpired(false));
            setCredentialsToSessionStorage(
              refreshResult.data as ITokenResponse
            );
            // retry the initial query
            result = await baseQuery(args, api, extraOptions);
          } else {
            window.location.pathname = paths.logoutPage;
            removeTokenData();
            api.dispatch(resetIdsExpectPatientId());
            api.dispatch(resetFamilyMembers());
          }
        } finally {
          // release must be called once the mutex should be released again.
          release();
        }
      } else {
        await mutex.waitForUnlock();
        result = await baseQuery(args, api, extraOptions);
      }
    }
    return result;
  };
