import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryWithReauth } from "../baseQueryWithReauth";

export const featureFlagsApi = createApi({
  reducerPath: "featureFlagsApi",
  baseQuery: baseQueryWithReauth(process.env.REACT_APP_API_HOST),
  endpoints: (build) => ({
    getFeatureFlags: build.query({
      query: () => "api/v1/featureManagement/enabledFeatures",
    }),
  }),
});

export const { useGetFeatureFlagsQuery } = featureFlagsApi;
