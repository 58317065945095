import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryWithReauth } from "../baseQueryWithReauth";
import {
  IViewerRequest,
  IViewerSaveForm,
} from "../interfaces/IViewerSaveForm.interface";

const urlBuilder = ({ formId, patientId, formAssignmentId }) =>
  `/${patientId}/formAssignments/${formAssignmentId}/forms/${formId}`;

const withResponseId = (responseId: string) =>
  responseId ? `?responseId=${responseId}` : "";

export const viewerApi = createApi({
  reducerPath: "viewerApi",
  baseQuery: baseQueryWithReauth(
    `${process.env.REACT_APP_API_HOST}api/v1/patients`
  ),
  endpoints: (build) => ({
    getFormById: build.query<unknown, IViewerRequest>({
      query: (params) => urlBuilder(params),
    }),
    getFormResponseById: build.query<unknown, IViewerRequest>({
      query: (params) =>
        `${urlBuilder(params)}/response${withResponseId(params.responseId)}`,
    }),
    submitForm: build.mutation<unknown, IViewerRequest>({
      query: (params) => ({
        url: `${urlBuilder(params)}/submit${withResponseId(params.responseId)}`,
        method: "POST",
      }),
    }),
    saveFormById: build.mutation<unknown, IViewerSaveForm>({
      query: (params) => ({
        url: `${urlBuilder(params)}/response${withResponseId(
          params.responseId
        )}`,
        method: "PUT",
        body: JSON.stringify(params.draft),
      }),
    }),
  }),
});

export const {
  useGetFormByIdQuery,
  useGetFormResponseByIdQuery,
  useSubmitFormMutation,
  useSaveFormByIdMutation,
} = viewerApi;
