import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../app/store/store";

interface LoginSlice {
  initialSearchParams: string;
  hasFamilyMembers: boolean;
  patientIdCompletedForms: string[];
}

const initialState: LoginSlice = {
  initialSearchParams: "",
  hasFamilyMembers: false,
  patientIdCompletedForms: [],
};

const loginSlice = createSlice({
  name: "loginSlice",
  initialState,
  reducers: {
    saveHasFamilyMember: (
      state,
      action: PayloadAction<{ hasFamilyMembers; url }>
    ) => {
      state.hasFamilyMembers = action.payload.hasFamilyMembers;
      state.initialSearchParams = action.payload.url;
    },
    saveCompletedFormPatientid: (state, action: PayloadAction<string>) => {
      state.patientIdCompletedForms.push(action.payload);
    },
    reset: () => {
      return initialState;
    },
  },
});

export const selectHasFamilyMembers = (state: RootState) =>
  state.loginSlice.hasFamilyMembers;

export const selectPatientIdCompletedForms = (state: RootState) =>
  state.loginSlice.patientIdCompletedForms;

export const selectInitialSearchParams = (state: RootState) =>
  state.loginSlice.initialSearchParams;

export const { saveCompletedFormPatientid, saveHasFamilyMember, reset } =
  loginSlice.actions;
export default loginSlice.reducer;
