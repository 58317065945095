import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryWithReauth } from "../baseQueryWithReauth";
import { PRACTICE_ID_HEADER_NAME } from "../constants/api.constant";
import { IDeviceInfo } from "../interfaces/IDeviceInfo.interface";
import {
  IRegisterDevice,
  IRegisteredDevice,
} from "../interfaces/IRegisterDevice.interface";

export const deviceManagementApi = createApi({
  reducerPath: "deviceManagementApi",
  baseQuery: baseQueryWithReauth(`${process.env.REACT_APP_API_HOST}api/v1`),
  endpoints: (build) => ({
    registerDevice: build.mutation<IRegisterDevice, IDeviceInfo>({
      query: ({ deviceId, deviceSecret, practiceId }) => ({
        url: `devices/${deviceId}/register`,
        body: { deviceSecret },
        method: "POST",
        headers: [[PRACTICE_ID_HEADER_NAME, practiceId]],
      }),
    }),
    getRegisteredDevice: build.query<IRegisteredDevice, Partial<IDeviceInfo>>({
      query: ({ deviceId, deviceSecret }) => ({
        url: `devices/${deviceId}?deviceSecret=${deviceSecret}`,
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const { useRegisterDeviceMutation, useGetRegisteredDeviceQuery } =
  deviceManagementApi;
