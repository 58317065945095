import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryWithReauth } from "../baseQueryWithReauth";
import { FORMS_INFO_TAG } from "../constants/api.constant";
import { IFamilyMember } from "../interfaces/IFamilyMember.interface";
import { IFormAssignment } from "../interfaces/IFormAssignment.interface";
import { IPatientInfo } from "../interfaces/IPatientInfo.interface";
import { getToken } from "../utils/api";

export const formsInfoApi = createApi({
  reducerPath: "formsInfoApi",
  tagTypes: [FORMS_INFO_TAG],
  baseQuery: baseQueryWithReauth(`${process.env.REACT_APP_API_HOST}api/v1`),
  endpoints: (build) => ({
    getFormAppointments: build.query<
      IFormAssignment,
      { patientId: string; formAssignmentId: string }
    >({
      async queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
        const accessToken = getToken();

        try {
          if (!accessToken) {
            return { error: "no token" };
          }

          const getForms = await fetchWithBQ(
            `/patients/${arg.patientId}/formAssignments/${arg.formAssignmentId}`
          );
          const patientInfo = await fetchWithBQ(`/patients/${arg.patientId}`);
          const forms = getForms.data as IFormAssignment;
          const patientInfoData = patientInfo.data as IPatientInfo;

          return {
            data: {
              ...forms,
              patientId: arg.patientId,
              formAssignmentId: arg.formAssignmentId,
              patientFirstName: patientInfoData?.firstName,
              patientLastName: patientInfoData?.lastName,
            },
          };
        } catch (error) {
          return { error };
        }
      },
      providesTags: [FORMS_INFO_TAG],
    }),
    getFamilyMembersInOffice: build.query<IFamilyMember[], string>({
      query: (patientId) => ({
        url: `patients/${patientId}/familyMembersInOffice`,
      }),
    }),
  }),
});

export const {
  useGetFormAppointmentsQuery,
  useLazyGetFamilyMembersInOfficeQuery,
  useLazyGetFormAppointmentsQuery,
} = formsInfoApi;
