import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LogoutSlice {
  isOpen: boolean;
  isLogout: boolean;
  isSessionExpired: boolean;
}

const initialState: LogoutSlice = {
  isOpen: false,
  isLogout: false,
  isSessionExpired: false,
};

const logoutSlice = createSlice({
  name: "logoutSlice",
  initialState,
  reducers: {
    setIsOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
    setIsLogout: (state, action: PayloadAction<boolean>) => {
      state.isLogout = action.payload;
    },
    setIsSessionExpired: (state, action: PayloadAction<boolean>) => {
      state.isSessionExpired = action.payload;
    },
  },
});

export const { setIsOpen, setIsLogout, setIsSessionExpired } =
  logoutSlice.actions;
export default logoutSlice.reducer;
