export const loadState = (key: string): string => {
  try {
    const serialState = sessionStorage.getItem(`appState.${key}`);
    if (serialState === null) {
      return undefined;
    }
    return JSON.parse(serialState);
  } catch (err) {
    return undefined;
  }
};
