import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootStateOrAny } from "react-redux";

import { IIndexable } from "../../app/interfaces/IIndexable.interface";
import { loadState } from "../../app/utils/persistState/loadState";
import { saveState } from "../../app/utils/persistState/saveState";
import { darkTheme } from "./themes/darkTheme";
import { lightTheme } from "./themes/lightTheme";

interface ThemeMapType {
  [key: string]: string | number | IIndexable;
}

interface AppStateType {
  currentBreakpoint: string;
  isLarge: boolean;
  theme: ThemeMapType;
  themeName: string;
}

const themeMap: IIndexable = {
  lightTheme,
  darkTheme,
};

const initialState: AppStateType = {
  currentBreakpoint: "",
  isLarge: false,
  theme: themeMap["lightTheme"],
  themeName: "lightTheme",
};

const persistedCurrentTheme = loadState("theme");

if (persistedCurrentTheme !== initialState.themeName) {
  initialState.themeName = persistedCurrentTheme;
  initialState.theme = themeMap[persistedCurrentTheme];
}

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    updateCurrentBreakpoint: (state, action) => {
      saveState("breakpoint", action.payload);
      return {
        ...state,
        currentBreakpoint: action.payload,
        isLarge: action.payload === "lg" || action.payload === "xl",
      };
    },
    updateTheme: (state, action: PayloadAction<string>) => {
      saveState("theme", action.payload);
      return {
        ...state,
        themeName: action.payload,
        theme: themeMap[action.payload],
      };
    },
  },
});

export const selectCurrentBreakpoint = (state: RootStateOrAny): string =>
  state.appState.currentBreakpoint;
export const selectIsLarge = (state: RootStateOrAny): boolean =>
  state.appState.isLarge;
export const selectCurrentTheme = (state: RootStateOrAny): ThemeMapType =>
  state.appState.theme;
export const selectCurrentThemeName = (state: RootStateOrAny): string =>
  state.appState.themeName;
export const { updateCurrentBreakpoint, updateTheme } = appSlice.actions;
export default appSlice.reducer;
