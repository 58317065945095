import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import {
  createMigrate,
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";

import appIdsSlice from "../../features/app/appIdsSlice";
import appReducer from "../../features/app/appSlice";
import featureFlagsSlice from "../../features/app/featureFlagsSlice";
import loginSlice from "../../features/Login/loginSlice";
import logoutSlice from "../../features/Logout/logoutSlice";
import registerDeviceSlice from "../../features/RegisterDevice/registerDeviceSlice";
import { deviceManagementApi } from "../services/deviceManagementApi";
import { featureFlagsApi } from "../services/featureFlagsApi";
import { formsInfoApi } from "../services/formsApi";
import { oauthApi } from "../services/oauthApi";
import { patientAuthenticationApi } from "../services/patientAuthenticationApi";
import { patientSettingsApi } from "../services/patientSettingsApi";
import { viewerApi } from "../services/viewerApi";
import { welcomePageApi } from "../services/welcomePageApi";
import formsDataSlice from "../shared/FormList/formsDataSlice";
import { migration } from "./migration";
import CURRENT_STORE_VERSION from "./version";

type TReducers = ReturnType<typeof rootReducer>;

const persistConfig = {
  key: "document-manager-root",
  storage,
  whitelist: ["appIdsSlice", "featureFlagsState"],
  stateReconciler: autoMergeLevel2,
  version: CURRENT_STORE_VERSION,
  migrate: createMigrate(migration),
};

export const rootReducer = combineReducers({
  [welcomePageApi.reducerPath]: welcomePageApi.reducer,
  [formsInfoApi.reducerPath]: formsInfoApi.reducer,
  [viewerApi.reducerPath]: viewerApi.reducer,
  [patientAuthenticationApi.reducerPath]: patientAuthenticationApi.reducer,
  [oauthApi.reducerPath]: oauthApi.reducer,
  [deviceManagementApi.reducerPath]: deviceManagementApi.reducer,
  [patientSettingsApi.reducerPath]: patientSettingsApi.reducer,
  [featureFlagsApi.reducerPath]: featureFlagsApi.reducer,
  appState: appReducer,
  appIdsSlice: appIdsSlice,
  logoutState: logoutSlice,
  formsDataState: formsDataSlice,
  registerDeviceState: registerDeviceSlice,
  featureFlagsState: featureFlagsSlice,
  loginSlice: loginSlice,
});

const persistedReducer = persistReducer<TReducers>(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      welcomePageApi.middleware,
      viewerApi.middleware,
      formsInfoApi.middleware,
      patientAuthenticationApi.middleware,
      oauthApi.middleware,
      deviceManagementApi.middleware,
      patientSettingsApi.middleware,
      featureFlagsApi.middleware
    ),
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
