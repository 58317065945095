import {
  ACCESS_TOKEN,
  PRACTICE_ID_HEADER_NAME,
  TOKEN_TYPE,
} from "../../constants/api.constant";

export const apiHeaders = (
  practiceId: string | undefined,
  headers: Headers
): Headers => {
  const accessToken = sessionStorage.getItem(ACCESS_TOKEN);
  const tokenType = sessionStorage.getItem(TOKEN_TYPE);

  if (accessToken && tokenType) {
    headers.set("Authorization", `${tokenType} ${accessToken}`);
  }

  if (practiceId) {
    headers.set(PRACTICE_ID_HEADER_NAME, practiceId);
  }

  headers.set("Content-Type", "application/json; charset=utf-8");
  return headers;
};
