import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IChannelInfo } from "../../app/interfaces/IPubnubChannelSettings";
import { RootState } from "../../app/store/store";

interface RegisterDeviceSlice {
  channelInfo: IChannelInfo;
  deviceId: string;
  deviceSecret: string;
  practiceId: string;
  isPubNubConnected: boolean;
}

const initialState: RegisterDeviceSlice = {
  channelInfo: null,
  deviceId: null,
  deviceSecret: null,
  practiceId: null,
  isPubNubConnected: false,
};

const registerDeviceSlice = createSlice({
  name: "logoutSlice",
  initialState,
  reducers: {
    setChannelInfo: (state, action: PayloadAction<IChannelInfo>) => {
      state.channelInfo = action.payload;
    },
    setDeviceId: (state, action: PayloadAction<string>) => {
      state.deviceId = action.payload;
    },
    setDeviceSecret: (state, action: PayloadAction<string>) => {
      state.deviceSecret = action.payload;
    },
    setPracticeId: (state, action: PayloadAction<string>) => {
      state.practiceId = action.payload;
    },
    setIsPubNubConnected: (state, action: PayloadAction<boolean>) => {
      state.isPubNubConnected = action.payload;
    },
  },
});

export const selectChannelInfo = (state: RootState) =>
  state.registerDeviceState.channelInfo;

export const selectDeviceId = (state: RootState) =>
  state.registerDeviceState.deviceId;

export const selectDeviceSecret = (state: RootState) =>
  state.registerDeviceState.deviceSecret;

export const selectPracticeId = (state: RootState) =>
  state.registerDeviceState.practiceId;

export const selectIsPubNubConnected = (state: RootState) =>
  state.registerDeviceState.isPubNubConnected;

export const {
  setChannelInfo,
  setDeviceId,
  setDeviceSecret,
  setPracticeId,
  setIsPubNubConnected,
} = registerDeviceSlice.actions;

export default registerDeviceSlice.reducer;
