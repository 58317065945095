import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryWithReauth } from "../baseQueryWithReauth";
import { IPubnubChannelSettings } from "../interfaces/IPubnubChannelSettings";

export const patientSettingsApi = createApi({
  reducerPath: "patientSettingsApi",
  baseQuery: baseQueryWithReauth(`${process.env.REACT_APP_API_HOST}api/v1`),
  endpoints: (build) => ({
    getChannelSettings: build.query<IPubnubChannelSettings, string>({
      query: (patientId: string): string => `/patients/${patientId}/settings`,
    }),
  }),
});

export const { useGetChannelSettingsQuery } = patientSettingsApi;
