import React, { Suspense } from "react";

import { createBrowserRouter } from "react-router-dom";

import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router";
import { PersistGate } from "redux-persist/integration/react";

import "@yapi/dataforms-components.viewer-web-component";
import "@yapi/dataforms-components.viewer-web-component/styles.css";
import ImageContainer from "./app/shared/ImageContainer/ImageContainer";
import { persistor, store } from "./app/store/store";

import "./index.css";

const AppComponent = React.lazy(() => import("./App"));

const router = createBrowserRouter([{ path: "*", Component: AppComponent }]);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<ImageContainer />} persistor={persistor}>
        <Suspense fallback={<ImageContainer />}>
          <RouterProvider router={router} />
        </Suspense>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
