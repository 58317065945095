import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryWithReauth } from "../baseQueryWithReauth";
import { IPracticeInfo } from "../interfaces/IPracticeInfo.interface";

export const welcomePageApi = createApi({
  reducerPath: "welcomePageApi",
  baseQuery: baseQueryWithReauth(`${process.env.REACT_APP_API_HOST}api/v1`),
  endpoints: (build) => ({
    getPatientInfo: build.query({
      async queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
        const patientsInfo = [];

        for (const patientId of arg) {
          if (!patientId) return;
          const response = await fetchWithBQ(`/patients/${patientId}`);
          response?.data && patientsInfo.push(response.data);
        }

        return {
          data: patientsInfo,
        };
      },
      onQueryStarted: (_arg, api): Promise<void> | void => {
        api.dispatch(api.updateCachedData);
      },
    }),
    getPracticesInfo: build.query<IPracticeInfo, string>({
      query: (practiceId) => `/practices/${practiceId}`,
    }),
  }),
});

export const { useGetPatientInfoQuery, useGetPracticesInfoQuery } =
  welcomePageApi;
