import { createSlice } from "@reduxjs/toolkit";

import { IFormAssignment } from "../../interfaces/IFormAssignment.interface";
import { RootState } from "../../store/store";

interface IFormDataSlice {
  formsData: IFormAssignment[];
}

const initialState: IFormDataSlice = {
  formsData: [],
};

const formsDataSlice = createSlice({
  name: "formsDataSlice",
  initialState,
  reducers: {
    saveFormsData: (state, action) => {
      state.formsData = action.payload;
    },

    resetFormsData: (state) => {
      state.formsData = [];
    },
  },
});

export const selectFormsData = (state: RootState) =>
  state.formsDataState.formsData;

export const { saveFormsData, resetFormsData } = formsDataSlice.actions;
export default formsDataSlice.reducer;
