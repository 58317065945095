import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { EFeatureFlags } from "../../app/enums/EFeatureFlags";
import { RootState } from "../../app/store/store";

interface IFeatureFlags {
  featureFlags: EFeatureFlags[];
}

const initialState: IFeatureFlags = {
  featureFlags: null,
};

const featureFlagsSlice = createSlice({
  name: "featureFlagsSlice",
  initialState,
  reducers: {
    setFeatureFlags: (state, action: PayloadAction<EFeatureFlags[]>) => {
      state.featureFlags = action.payload;
    },
  },
});

export const selectFeatureFlags = (state: RootState): EFeatureFlags[] =>
  state.featureFlagsState.featureFlags;

export const { setFeatureFlags } = featureFlagsSlice.actions;

export default featureFlagsSlice.reducer;
