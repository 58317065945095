import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryWithReauth } from "../baseQueryWithReauth";
import {
  LOGIN_INFORMATION_TAG,
  PRACTICE_ID_HEADER_NAME,
} from "../constants/api.constant";
import { ILoginInformation } from "../interfaces/ILoginInformation.interface";

type LoginInformationQueryParams = {
  readonly practiceId: string;
  readonly patientId: string;
  readonly formAssignmentId: string;
};

export const patientAuthenticationApi = createApi({
  reducerPath: "authenticationApi",
  tagTypes: [LOGIN_INFORMATION_TAG],
  baseQuery: baseQueryWithReauth(`${process.env.REACT_APP_API_HOST}api/v1`),
  endpoints: (build) => ({
    getLoginInformation: build.query<
      ILoginInformation,
      LoginInformationQueryParams
    >({
      query: ({ patientId, formAssignmentId, practiceId }) => ({
        url: `/patients/${patientId}/loginInformation?formAssignmentId=${formAssignmentId}`,
        headers: [[PRACTICE_ID_HEADER_NAME, practiceId]],
      }),
      providesTags: [LOGIN_INFORMATION_TAG],
    }),
  }),
});

export const { useGetLoginInformationQuery } = patientAuthenticationApi;
