const FORMS_INFO_TAG = "formsInfo";
const LOGIN_INFORMATION_TAG = "loginInformation";

const PRACTICE_ID_HEADER_NAME = "X-Yapi-Client-Guid";
const ACCESS_TOKEN = "access_token";
const TOKEN_TYPE = "token_type";
const REFRESH_TOKEN = "refresh_token";
const EXPIRES_IN = "expires_in";
const DEVICE_ID = "deviceId";
const DEVICE_SECRET = "deviceSecret";
const CHANNEL_INFO = "channelInfo";
const QUERY_PARAMS_FOR_BOOKMARK = "queryParamsForBookmark";
const QUERY_PARAMS_FOR_LOGIN_PAGE = "queryParamsForLoginPage";

export {
  FORMS_INFO_TAG,
  CHANNEL_INFO,
  EXPIRES_IN,
  DEVICE_ID,
  DEVICE_SECRET,
  REFRESH_TOKEN,
  TOKEN_TYPE,
  ACCESS_TOKEN,
  LOGIN_INFORMATION_TAG,
  PRACTICE_ID_HEADER_NAME,
  QUERY_PARAMS_FOR_BOOKMARK,
  QUERY_PARAMS_FOR_LOGIN_PAGE,
};
